.fraction {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    letter-spacing: 0.001em;
    text-align: center;
    font-size: 14px;
}
.fraction > span {
    display: block;
    padding: 0.1em;
}
.fraction span.denominator {
    border-top: thin solid black;
}
.fraction span.bar {
    display: none;
}

.settings-modal .modal-content {
    left: 50%;
    padding: 32px 64px;
    top: 50%;
    transform: translate(-50%, 25%);
    width: 614px;
}
.form-label {
    margin-bottom: 0;
}
.form-control {
    border-color: #ABB1BA;
    height: 40px;
}

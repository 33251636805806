.getting-started-modal .modal-content {
    padding: 32px;
    transform: translate(0%, 7%);
}
.getting-started-item {
    padding-bottom: 10px;
}

.getting-started-modal-close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    padding: 20px;
    right: -10px;
    top: -14px;
    z-index: 2;
}

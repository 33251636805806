.group-code-button {
    background-color: #292865;
    border: none;
    border-radius: 6px;
    font-size: 12px;
    height: 32px;
    padding: 0 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.group-code-button:hover {
    background-color: #524FC9;
}

.group-code-button:active {
    transform: scale(0.95);
}

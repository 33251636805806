.inspector {
    position: fixed;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 1000;
}

.resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background: transparent;
    z-index: 10;
}

.resize-handle.top-left,
.resize-handle.top-right,
.resize-handle.bottom-left,
.resize-handle.bottom-right {
    width: 15px;
    height: 15px;
    z-index: 15; /* Ensure corners are above side handles */
}

.resize-handle:hover {
    background: rgba(0, 0, 0, 0.1);
}

.resize-handle.top-left {
    top: -5px;
    left: -5px;
    cursor: nwse-resize;
}

.resize-handle.top-right {
    top: -5px;
    right: -5px;
    cursor: nesw-resize;
}

.resize-handle.bottom-left {
    bottom: -5px;
    left: -5px;
    cursor: nesw-resize;
}

.resize-handle.bottom-right {
    bottom: -5px;
    right: -5px;
    cursor: nwse-resize;
}

.resize-handle.left {
    top: 0;
    left: -5px;
    height: 100%;
    cursor: ew-resize;
}

.resize-handle.right {
    top: 0;
    right: -5px;
    height: 100%;
    cursor: ew-resize;
}

.resize-handle.top {
    top: -5px;
    left: 0;
    width: 100%;
    cursor: ns-resize;
}

.resize-handle.bottom {
    bottom: -5px;
    left: 0;
    width: 100%;
    cursor: ns-resize;
}

.challenge-prop {
    margin-bottom: 10px;
}

.horizontal {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.input-number {
    width: 40px;
    text-indent: 7px;
}

.header-margin-right {
    margin-right: 10px;
}

.round-box {
    border-radius: 100px;
    border-width: 1px;
}

.full-width {
    width: 100%;
}

.preview-button-div {
    display: flex;
    justify-content: center;
}

.preview-button {
    color: #fff;
    background: #0275d8;
    border-color: #0275d8;
}

.target-answer-width {
    width: 50px;
}

.add-button {
    margin-top: 16px;
}

.crazy-calculator-question .accordion-button:focus {
    box-shadow: none;
}

.crazy-calculator-question .accordion-button:not(.collapsed) {
    background-color: white;
    color: unset;
}
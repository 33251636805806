.student-login-card-close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    padding: 20px;
    right: -8px;
    top: -12px;
    z-index: 2;
}

.icon-nav-item {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    gap: 12px;
    padding: 8px 12px;
}

.icon-nav-item-active {
    align-items: center;
    background-color: #F2F4F6;
    border-radius: 6px;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    gap: 12px;
    padding: 8px 12px;
}

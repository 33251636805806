.prompt {
    font-family: "MathJax Main Regular";
    font-size: 20px;
    line-height: normal;
    padding-bottom: 34px;
}
.answer {
    font-family: "MathJax Main Regular";
    font-size: 20px;
    line-height: 1;
}

.group-code-card-header-container {
    border-bottom: 1px solid #EEECE7;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    padding-bottom: 16px;
}

.group-code-card-header {
    color: #002E19;
    font-size: 18px;
    font-weight: bold;
    height: 26px;
}

.dropdown-menu {
    background: white;
    border: 1px solid lightgray;
    z-index: 9999;
}
.dropdown-item {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: unset;
}
.dropdown-item:hover {
    background: lightgray;
    border: none;
    text-shadow: 1px 1px lightgray;
}
.center-display {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
.group-code-view-message-alert-close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem;
}

.group-code-item, .group-code-item-shift {
    display: block;
    max-width: 218px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.group-code-item-shift {
    padding-left: 14px;
}

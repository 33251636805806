.session-history .accordion-button {
    background-color: #F2F4F7;
    padding: 0;
}

.session-history .accordion-button:focus {
    box-shadow: none;
}

.session-history .accordion-button:after {
    align-items: center;
    background-color: #605DEC;
    background-image: url("/src/assets/Icon-CornersOut.svg");
    background-repeat: no-repeat;
    border-radius: 6px;
    justify-content: center;
    margin-right: 24px;
    padding: 8px;
}

.session-history .accordion-button:not(.collapsed) {
    background-color: #F2F4F7;
}

.session-history .accordion-button:not(.collapsed):after {
    background-image: url("/src/assets/Icon-CornersIn.svg");
}

.session-history .accordion-in-progress>.accordion-button {
    background-color: #F2F4F7;
}

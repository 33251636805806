.session-summary-prompt-console .accordion-button:not(.collapsed) {
    background-color: white;
    color: unset;
}

.session-summary-prompt-console .accordion-button {
    padding: 0 24px;
}

.session-summary-prompt-console .accordion-button:focus {
    box-shadow: none;
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ABB1BA;
    opacity: 1; /* Firefox */
}

@keyframes drop-in {
    from {
        opacity: 0;
        transform: translate3d(0, -25%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.drop-in {
    animation-name: drop-in;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

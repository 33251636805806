.login-decoration {
    align-self: center;
    background-image: linear-gradient(rgb(68, 76, 247) 1px, transparent 1px), linear-gradient(to right, rgb(68, 76, 247) 1px, rgb(229, 229, 247) 1px);
    background-size: 20px 20px;
    display: flex;
    max-height: 100vh;
    mask-image: radial-gradient(50% 50% at 50% 50%, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    justify-self: center;
    overflow-y: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 75vh;
    height: 75vh;
}

@media (orientation: portrait) {
    .login-decoration-portrait {
        width: 100vw;
        height: 100vw;
    }
}

.login-card {
    border: none !important;
    border-radius: 12px !important;
    display: flex;
    min-height: 350px;
    height: fit-content;
    justify-content: center;
    margin: auto;
    max-width: 800px;
    width: 450px;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@media (max-width: 768px) {
    .login-card-small-width {
        width: 400px;
    }
}

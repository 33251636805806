.valid-answer {
    background: #84ff90;
}

.answer-input {
    width: 85%;
    border-radius: 100px;
    border-width: 1px;
    margin-bottom: 5px;
}

.margin-right-5 {
    margin-right: 5px;
}
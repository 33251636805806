.brokenKey {
    width: 30px;
    border-radius: 100px;
    border-width: 1px;
    margin-bottom: 5px;
    text-indent: 7px;
}

.margin-right-5 {
    margin-right: 5px;
}